import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaBuilding,
  FaEnvelope,
  FaMailBulk,
  FaPhoneAlt
} from "react-icons/fa";
import MobileMenu from "../mobile-menu";
import "flag-icon-css/css/flag-icons.min.css";
import Image from "next/image";

const Header = ({ company }) => {
  return (
    <Fragment>
      <section className="gauto-header-top-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="header-top-left">
                <p>{`${company.nickName}`}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="header-top-right">
                <a href={`tel:+55${company.phone}`}>
                  <FaPhoneAlt /> {
                    company.phone.length === 10
                      ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 6)}-${company.phone.substring(6, 10)}`
                      : company.phone.length === 11
                        ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 7)}-${company.phone.substring(7, 11)}`
                        : company.phone
                  }
                </a>
                <a href={`mailto:${company.email}`}><FaEnvelope /> {company.email}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <header className="gauto-main-header-area">
        <Container>
          <Row>
            <Col md={3}>
              <div className="site-logo">
                <a href="/">
                  <Image
                    src={company.logo}
                    alt="logo"
                    width={200}
                    height={63}
                    priority={true}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }} />
                </a>
              </div>
            </Col>
            <Col lg={6} sm={9}>
              <div className="header-promo">
                <div className="single-header-promo">
                  <div className="header-promo-icon">
                    <FaBuilding />
                  </div>
                  <div className="header-promo-info">
                    <h3>{`${company.city}, ${company.federativeUnit}`}</h3>
                    <p>{`${company.address}`}</p>
                  </div>
                </div>
              </div>
            </Col>
            <div className="col-lg-3">
              <div className="header-action">
                <a href="/contato">
                  <FaMailBulk /> Entre em Contato
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </header>
      <section className="gauto-mainmenu-area">
        <Container>
          <Row>
            <Col lg={11}>
              <div className="mainmenu">
                <nav>
                  <ul id="gauto_navigation">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/novos">0 Km</a>
                    </li>
                    <li>
                      <a href="/seminovos">Seminovos</a>
                    </li>
                    {/* <li>
                    <a href="/blindados">Blindados</a>
                    </li> */}
                    <li>
                      <a href="/financiamento">Financiamento</a>
                    </li>
                    <li>
                      <a href="/venda-seu-veiculo">Venda Seu Veículo</a>
                    </li>
                    {/* <li>
                      <a href="/seguro">Seguro</a>
                    </li> */}
                    <li>
                      <a href="/sobre">Sobre</a>
                    </li>
                    <li>
                      <a href="/contato">Contato</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
            <Col lg={1} sm={12}>
              <div className="main-search-right">
                <MobileMenu />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Header;