import Image from "next/image";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaMailBulk,
  FaBuilding,
  FaClock,
  FaYoutube,
} from "react-icons/fa";


const Footer = ({ company }) => {
  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="single-footer">
                <div className="footer-logo">
                  <a href="/">
                    <Image
                      src={company.logo}
                      alt="logo"
                      width={200}
                      height={63}
                      priority={true}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }} />
                  </a>
                </div>
                <p>
                A GILVAN VEICULOS foi estabelecida com uma missão clara, ser sua parceira confiável para facilitar a compra, venda ou troca do seu veículo.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="single-footer quick_links">
                <h3>Links Rápidos</h3>
                <ul className="quick-links">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/novos">0 Km</a>
                  </li>
                  <li>
                    <a href="/seminovos">Seminovos</a>
                  </li>
                  {/* <li>
                    <a href="/blindados">Blindados</a>
                  </li> */}
                  <li>
                    <a href="/financiamento">Financiamento</a>
                  </li>
                  <li>
                    <a href="/venda-seu-veiculo">Venda Seu Veículo</a>
                  </li>
                  {/* <li>
                    <a href="/seguro">Seguro</a>
                  </li> */}
                  <li>
                    <a href="/sobre">Sobre</a>
                  </li>
                  <li>
                    <a href="/contato">Contato</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="single-footer">
                <h3>Informações </h3>
                <p>
                  <FaBuilding />{" "}Endereço: {`${company.address} - ${company.city} - ${company.federativeUnit}`}
                </p>
                <ul>
                  <li><FaWhatsapp />{" "}Whatsapp: (11) 94739-6211 </li>
                  <li><FaMailBulk />{" "}E-mail: {company.email}</li>
                  <li><FaClock />{" "}Funcionamento: <br />Segunda à Sexta : 8:00 as 18:00 <br />Sábados : 8:00 às 16:00</li>
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="single-footer map">
                <h3>Localização </h3>
                <iframe
                  src={`https://maps.google.com/maps?q=${company.address} - ${company.city} - ${company.federativeUnit}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="copyright">
                <p>
                  © Todos os direitos reservados. Desenvolvido por {" "}
                  <a href="https://www.bndv.com.br/" target={'_blank'} rel="noreferrer">
                    BNDV - Banco Nacional de Veículos{" "}
                  </a>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="footer-social">
                <ul>
                  {company.facebook ? <li><a href={company.facebook} target={'_blank'} rel="noreferrer"><FaFacebookF /></a></li> : null}
                  {company.instagram ? <li><a href={company.instagram} target={'_blank'} rel="noreferrer"><FaInstagram /></a></li> : null}
                  {company.youtube ? <li><a href={company.youtube} target={'_blank'} rel="noreferrer"><FaYoutube /></a></li> : null}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;