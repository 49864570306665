import React, { Component } from "react";

export default class MobileMenu extends Component {
  state = { isMenuShow: false };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  render() {
    const { isMenuShow } = this.state;
    return (
      <div className="responsiveMenu">
        <nav
          id="mobileMenu"
          className={`mobileMenu ${isMenuShow ? "active" : ""}`}
        >
          <ul className="responsivemenu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/novos">0 Km</a>
            </li>
            <li>
              <a href="/seminovos">Seminovos</a>
            </li>
            {/* <li>
              <a href="/blindados">Blindados</a>
            </li> */}
            <li>
              <a href="/financiamento">Financiamento</a>
            </li>
            <li>
              <a href="/venda-seu-veiculo">Venda Seu Veículo</a>
            </li>
            {/* <li>
              <a href="/seguro">Seguro</a>
            </li> */}
            <li>
              <a href="/sobre">Sobre</a>
            </li>
            <li>
              <a href="/contato">Contato</a>
            </li>
          </ul>
        </nav>

        <div
          className={`spinner-master ${isMenuShow ? "active" : ""}`}
          onClick={this.menuHandler}
        >
          <div id="spinner-form" className="spinner-spin">
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
